import React, { Fragment } from "react"
import { Breadcrumb, Container, Row, Col } from "react-bootstrap"
import bannerImg from "../assets/images/about-banner.jpg"
import aboutImg from "../assets/images/about.jpg"
import LayoutAnimate from "../components/animation/LayoutAnimate"
import { Parallax } from "react-parallax"
const About = () => {
  return (
    <Fragment>
      <LayoutAnimate>
        <section className="page-banner-section">
          <Parallax
            bgImage={bannerImg}
            bgImageAlt="contact section"
            strength={200}
            className="cta-section about-banner-section"
            renderLayer={percentage => (
              <div
                  style={{
                      position: 'absolute',
                      background: `linear-gradient(to right, rgba(2, 23, 32,0.9),rgba(2, 23, 32,0.2))`,
                      left: '0',
                      top: '0',
                      width: '100%',
                      height: '100%',
                  }}
              />
          )}
          >
            <div className="page-banner-wrapper">
              <Container
                className="page-banner-title-container"
                data-sal-duration="1000"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="200"
              >
                <h2>About Us</h2>
              </Container>
              <div className="breadcrumnb-wrapper">
                <Container
                  className="breadcrumb-container pb-3"
                  data-sal-duration="1000"
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-delay="400"
                >
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                  </Breadcrumb>
                </Container>
              </div>
            </div>
          </Parallax>
          {/* <div className="bg-img-box">
          <img src={bannerImg} alt="product banner image" />
        </div> */}
        </section>
        <section className="about-section layout-padding">
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <div
                  className="detail-box"
                  data-sal-duration="1000"
                  data-sal="slide-right"
                  data-sal-easing="ease"
                >
                  <div className="heading-container mb-3">
                  <h2 className="section-heading">About Us</h2>
                  </div>
                  <p>
                    Shan Petro is known as Technocommercial company and also
                    well established company with young Technocrats who are well
                    versed with Ultra Modern Technologies used around the world.
                    The base of the company has strong foundation of Principles
                    and Philosophy.
                  </p>
                  <p>
                    Based on best services, right product application and
                    providing ultimate satisfaction of consumers needs.We avail
                    this valuable opportunity to introduce ourselves as one of
                    the accredited Manufactures of Automotive and Industrial
                    lubricating oils.
                  </p>
                  <p className="mb-4">
                    We are an established company with Manufacturing and
                    Blending unit in Rajkot. We started in a small way but with
                    Dedication, Sincerity and Quality Persistence.
                  </p>
                </div>
              </Col>
              <Col lg={6} className="mt-3 mt-lg-0">
                <div
                  className="img-box"
                  data-sal-duration="1000"
                  data-sal="slide-left"
                  data-sal-easing="ease"
                >
                  <img
                    src={aboutImg}
                    alt="about us image"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="layout-padding-bottom">
          <Container>
            <div
              className="our-vision-container"
              data-sal-duration="1000"
              data-sal="slide-up"
              data-sal-easing="ease"
            >
              <h2 className="section-heading mb-4">Our Vision</h2>
              <p>Dear Friends,</p>
              <p>
                We are work for people to Treat &amp; Educate for right product
                application and getting optimum performance as family. And that
                has a capacity to accelerate world’s economy at every level.
              </p>
              <p>
                With best Regards,
                <br />
                Bipin Patel
                <br />
                (Founder -SHAN PETRO)
              </p>
            </div>
          </Container>
        </section>
      </LayoutAnimate>
    </Fragment>
  )
}

export default About
